import _ from 'lodash'
import { getModules } from '@wix/simple-module-loader'
import { getComponentsSDKLoader } from '@wix/thunderbolt-components-registry/getComponentsSDKLoader'
import { createPromise, logSdkError, logSdkWarning, createProxy } from '@wix/thunderbolt-commons'
import type { IPlatformLogger, PlatformEnvData, StorageInitData } from '@wix/thunderbolt-symbols'
import { createStorageAPI } from '../storage/storageAPI'
import type { ComponentSdksLoader, CoreSdkLoaders, CreateWixStorageAPI, WixStorageAPI } from '../types'
import type { ControllersExports, InitArgs } from './types'
import { Applications } from './applications'
import { DocumentSdkFactory } from './componentsSDK/Document'
import { createPlatformApi } from './appsAPI/platformAPI'
import { ModuleFederationManagerFactory } from './moduleFederationManager'
import { PlatformLogger } from './modules/platformLogger'
import { ViewerHandlers } from './modules/viewerHandlers'
import { UnfinishedTasks } from './modules/unfinishedTasks'
import { ModelsApiProvider } from './modules/modelsApiProvider'
import AppsUrls from './modules/appsUrls'
import LinkUtils from './modules/linkUtils'
import BsiManager from './modules/bsiManager'
import PlatformBi from './modules/platformBi'
import WarmupData from './modules/warmupData'
import WixSelector from './modules/wixSelector'
import CommonConfig from './modules/commonConfig'
import AppsPublicApi from './modules/appsPublicApi'
import InstanceCache from './modules/instanceCache'
import PlatformUtils from './modules/platformUtils'
import RegisterEvent from './modules/registerEvent'
import SsrCacheHints from './modules/ssrCacheHints'
import BlocksAppsUtils from './modules/blocksAppsUtils'
import FedopsWebVitals from './modules/fedopsWebVitals'
import LocationManager from './modules/locationManager'
import SetPropsManager from './modules/setPropsManager'
import ClientSpecMapApi from './modules/clientSpecMapApi'
import ControllerEvents from './modules/controllerEvents'
import PlatformBiLogger from './modules/platformBiLogger'
import SdkFactoryParams from './modules/sdkFactoryParams'
import ComponentSdkState from './modules/componentSdkState'
import ModelPropsUpdater from './modules/modelPropsUpdater'
import WixCodeApiFactory from './modules/wixCodeApiFactory'
import PlatformAnimations from './modules/platformAnimations'
import PlatformEssentials from './modules/platformEssentials'
import StaticEventsManager from './modules/staticEventsManager'
import ComponentSdksManager from './modules/componentSdksManager'
import ConsentPolicyManager from './modules/consentPolicyManager'
import WixCodeViewerAppUtils from './modules/wixCodeViewerAppUtils'
import DataBindingViewerAppUtils from './modules/dataBindingViewerAppUtils'
import WixCodeNamespacesRegistry from './modules/wixCodeNamespacesRegistry'

type PlatformState = {
	createStorageApi: CreateWixStorageAPI
	loadComponentSdksPromise: Promise<ComponentSdksLoader>
}

export function createPlatformAPI() {
	const { promise: waitForInit, resolver: initDone } = createPromise<PlatformState>()

	return {
		initPlatformOnSite({ logger, platformEnvData }: { logger: IPlatformLogger; platformEnvData: PlatformEnvData }) {
			const siteStorageApi: CreateWixStorageAPI = createStorageAPI()

			initDone({
				createStorageApi: (appPrefix: string, handlers: any, storageInitData: StorageInitData): WixStorageAPI => {
					return siteStorageApi(appPrefix, handlers, storageInitData)
				},
				loadComponentSdksPromise: getComponentsSDKLoader({
					platformEnvData,
					logger,
				}) as any, // TODO: remove `as any` after https://github.com/wix-private/editor-elements/pull/3443 is merged
			})
		},

		async runPlatformOnPage({
			bootstrapData,
			importScripts,
			moduleLoader,
			invokeViewerHandler,
			modelsProviderFactory,
			sessionService,
			debugApi,
			flushPendingUpdates = _.noop,
			onPageWillUnmount,
		}: InitArgs) {
			const { createStorageApi, loadComponentSdksPromise } = await waitForInit

			const viewerHandlers = ViewerHandlers(invokeViewerHandler, bootstrapData)
			const unfinishedTasks = UnfinishedTasks(viewerHandlers)
			const logger = PlatformLogger(bootstrapData, sessionService, unfinishedTasks)
			logger.interactionStarted('initialisation')
			const modelBuilder = ModelsApiProvider(bootstrapData, modelsProviderFactory, logger)
			const modelsApi = await logger.runAsyncAndReport('getAllModels', modelBuilder.getModelApi)

			const getCompByRefId = (compId: string) =>
				createProxy((functionName: string) => (...args: any) => {
					// wait for all stores to be updated before a potential (re)render of a component.
					// specifically, when changing a state of a state box, we want the target state props to be ready.
					flushPendingUpdates()
					return handlers.platform.invokeCompRefFunction(compId, functionName, args)
				})

			const modules = await getModules({
				modelsApi,
				moduleLoader,
				bootstrapData,
				importScripts,
				getCompByRefId,
				sessionService,
				viewerHandlers,
				unfinishedTasks,
				onPageWillUnmount,
				invokeViewerHandler,
				modelsProviderFactory,
				platformLogger: logger,
				loadComponentSdksPromise,
				AppsUrls,
				LinkUtils,
				BsiManager,
				PlatformBi,
				WarmupData,
				WixSelector,
				CommonConfig,
				AppsPublicApi,
				InstanceCache,
				PlatformUtils,
				RegisterEvent,
				SsrCacheHints,
				BlocksAppsUtils,
				FedopsWebVitals,
				LocationManager,
				SetPropsManager,
				ClientSpecMapApi,
				ControllerEvents,
				PlatformBiLogger,
				SdkFactoryParams,
				ComponentSdkState,
				ModelPropsUpdater,
				WixCodeApiFactory,
				PlatformAnimations,
				PlatformEssentials,
				StaticEventsManager,
				ComponentSdksManager,
				ConsentPolicyManager,
				WixCodeViewerAppUtils,
				DataBindingViewerAppUtils,
				WixCodeNamespacesRegistry,
			})

			const { viewerHandlers: handlers } = viewerHandlers

			const {
				appsUrls,
				bsiManager,
				platformBi,
				wixSelector,
				commonConfig,
				appsPublicApi,
				platformUtils,
				ssrCacheHints,
				blocksAppsUtils,
				fedopsWebVitals,
				setPropsManager,
				clientSpecMapApi,
				controllerEvents,
				componentSdkState,
				modelPropsUpdater,
				wixCodeApiFactory,
				staticEventsManager,
				componentSdksManager,
				wixCodeViewerAppUtils,
				dataBindingViewerAppUtils,
				platformEssentials: essentials,
				instanceCache: sdkInstancesCache,
			} = modules

			// TODO: init all Initializable modules
			;[platformBi, fedopsWebVitals, modelPropsUpdater, appsPublicApi, ssrCacheHints].forEach((module) => module.init())

			const platformEnvData = bootstrapData.platformEnvData

			const { waitForUpdatePropsPromises, createSetPropsForOOI } = setPropsManager

			const reporter = {
				logSdkError,
				logSdkWarning,
			}
			const controllersExports: ControllersExports = {}

			const AppControllerSdkLoader = async () => {
				const { AppControllerSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerSdk({ controllersExports, modelsApi, controllerEvents })
			}

			const AppWidgetSdkLoader = async () => {
				const { AppControllerWithChildrenSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerWithChildrenSdk({ controllersExports, modelsApi, controllerEvents })
			}

			const createPlatformApiForApp = createPlatformApi({
				platformEnvData,
				platformUtils,
				createStorageApi,
				handlers,
			})

			const moduleFederationManager = ModuleFederationManagerFactory({
				logger,
				moduleLoader,
				appsUrls,
				clientSpecMapApi,
				platformEnvData,
			})

			const { runApplications, createRepeatedControllers } = Applications({
				appsPublicApi,
				platformUtils,
				clientSpecMapApi,
				appsUrls,
				modelsApi,
				bootstrapData,
				importScripts,
				wixCodeViewerAppUtils,
				blocksAppsUtils,
				dataBindingViewerAppUtils,
				wixSelector,
				logger,
				wixCodeApiFactory,
				createSetPropsForOOI,
				waitForUpdatePropsPromises,
				controllersExports,
				createPlatformApiForApp,
				bsiManager,
				essentials,
				commonConfig: commonConfig.get(),
				handlers,
				moduleFederationManager,
				sdkInstancesCache,
				debugApi,
			})

			const RepeaterSdkLoader = async () => {
				const { RepeaterSdk } = await import('./componentsSDK/repeaters/Repeater' /* webpackChunkName: "Repeater.corvid" */)
				return RepeaterSdk({
					modelsApi,
					wixSelector,
					reporter,
					sdkInstancesCache,
					componentSdkState,
					platformEnvData,
					createRepeatedControllers,
					handlers,
				})
			}

			const DocumentSdkLoader = async () =>
				Promise.resolve(
					DocumentSdkFactory({
						modelsApi,
						wixSelector,
						currentPageId: bootstrapData.currentPageId,
					})
				)

			const coreSdks: CoreSdkLoaders = {
				AppController: AppControllerSdkLoader,
				AppWidget: AppWidgetSdkLoader,
				TPAWidget: AppControllerSdkLoader,
				TPASection: AppControllerSdkLoader,
				TPAMultiSection: AppControllerSdkLoader,
				TPAGluedWidget: AppControllerSdkLoader,
				tpaWidgetNative: AppControllerSdkLoader,
				Repeater: RepeaterSdkLoader,
				Document: DocumentSdkLoader,
			}
			componentSdksManager.fetchComponentsSdks(coreSdks)
			logger.interactionEnded('initialisation')

			await logger.runAsyncAndReport('runApplications', () => runApplications(modelsApi.getApplicationIds()))
			// calling it here because we need to run all the applications, register the controllers APIs, run and finish all PageReady/OnReady, before executing any static events handlers.
			// some handlers may depends on the apis being registered and onReady been called,
			staticEventsManager.triggerStaticEventsHandlers() // TODO do we need to run this is SSR?
		},
	}
}
